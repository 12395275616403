import './style.css'
import * as THREE from 'three'
import { Group, RGB_ETC1_Format, Vector2, Vector3 } from 'three'
import * as Tone from 'tone'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = new THREE.Color(0x0028FF); // blue background 

const clock = new THREE.Clock() // use clock for timer 
let elapsedTime = clock.getElapsedTime()

// const ambientLight = new THREE.AmbientLight(0xc4dce5, 1.07); //#d6e7ed 0xc4dce5
// scene.add(ambientLight);

const ambientLight = new THREE.AmbientLight(0xc4dce5, 0.8); //#d6e7ed current: 0xc4dce5, 0.6
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2, 100); //0xc4dce5 current 0xffffff, .5, 100
directionalLight.position.set(5, 100, 5); //(200, 500, 300)  5, 100, 5
directionalLight.rotation.z = -60; // -60 (200, 500, 300)
directionalLight.castShadow = true; // default false
scene.add(directionalLight);

//Set up shadow properties for the light
directionalLight.shadow.mapSize.width = 256; // 512 default
directionalLight.shadow.mapSize.height = 256; // 512 default
directionalLight.shadow.camera.near = -.05; // -.05 default
directionalLight.shadow.camera.far = 125; // 500 default
directionalLight.shadow.bias = 0.0001;

// const d = 100;
directionalLight.shadow.camera.left = -60; // -60
directionalLight.shadow.camera.right = 60; // 60
directionalLight.shadow.camera.top = 60; // 60
directionalLight.shadow.camera.bottom = -60; // -60

//SYNTH
const synth = new Tone.PolySynth().toDestination();
synth.volume.value = -20;

//SPEECH
let utter;

// RESPONSIVE ARRAYS

// eating tones 
let toneArray = [["E3", "A2", "C4"], ["F3", "A2", "C4"], ["C3", "G2", "F2"], ["D2", "G2", "B2"]]

// flock
let flockDestination = new THREE.Vector3(-20, 0, -30);
let runflag = false;
let changeLoc = false;

// bird color
let headColorArray = [0xe7cdab, 0xcfb89b, 0xd4b792]
let bodyColorArray = [0xe7cdab, 0xcfb89b, 0xd4b792]
let wingColorArray = [0xb6966c, 0xad9076, 0x8b7660]
let legColorArray = [0xcd9828, 0x292929, 0x363636]

// text display area 
let messageArray = [];
let messageNum = 0;
messageArray[0] = "START 🔊"
messageArray[1] = "HEY YOU SANDPIPERS!"
messageArray[2] = "I LOVE THE WAY YOU HAVE NO HAIR"
messageArray[3] = "SPREADING YOUR STYLE EVERYWHERE"
messageArray[4] = "YOU'RE LIKE A STYLE FOUNTAIN"
messageArray[5] = "ENOUGH ZAZZ FOR A WHOLE MOUNTAIN"
messageArray[6] = "I LIKE THE WAY YOU CLIP CLOP"
messageArray[7] = "YOU DO IT LIKE BLURRY"
messageArray[8] = "I LIKE THE WAY YOU EAT"
messageArray[9] = "YOU DO IT LIKE OBSOLETE"

let isAnyoneEating = false;

 document.getElementById("overlay").innerHTML = messageArray[messageNum]

// SKY
const skyPlane = new THREE.Mesh(
    new THREE.PlaneGeometry(2000, 1000, 32, 32),
    new THREE.MeshLambertMaterial({ color: 0x9f8fee })
)

skyPlane.applyMatrix4(new THREE.Matrix4().makeTranslation(250, -5, 0)); // 205
skyPlane.rotation.y = THREE.Math.degToRad(-90)
scene.add(skyPlane)

// SAND
const groundPlane = new THREE.Mesh(
    new THREE.PlaneGeometry(1000, 800, 32, 32),
    new THREE.MeshLambertMaterial({ color: 0xa18355 })
)

groundPlane.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -5, 0));
groundPlane.rotation.x = THREE.Math.degToRad(-90)
groundPlane.castShadow = false;
groundPlane.receiveShadow = true;
scene.add(groundPlane)

const headMat = new THREE.MeshLambertMaterial({ color: 0xe7cdab });
const beakMat = new THREE.MeshLambertMaterial({ color: 0x333333 });
const eyeMat = new THREE.MeshLambertMaterial({ color: 0xffffff })
const pupilMat = new THREE.MeshLambertMaterial({ color: 0x000000 })
const bodyMat = new THREE.MeshLambertMaterial({ color: 0xe7cdab })
const normalMat = new THREE.MeshNormalMaterial()
const shoulderMat = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const wingMat = new THREE.MeshLambertMaterial({ color: 0xb6966c })
const buttTailMat = new THREE.MeshLambertMaterial({ color: 0xe7cdab })
const legsMat = new THREE.MeshLambertMaterial({ color: 0xcd9828 })
const featherMat = new THREE.MeshLambertMaterial({ color: 0x754636 })
const headGeo = new THREE.SphereGeometry(1, 16, 32)
const beakGeo = new THREE.ConeGeometry(.2, 2.5, 8, 1)
const eyeGeo = new THREE.SphereGeometry(.25, 16, 32)
const pupilGeo = new THREE.SphereGeometry(.2, 16, 32)
const bodyGeo = new THREE.CylinderGeometry(.75, 1.5, 3.5, 32)
const neckGeo = new THREE.SphereGeometry(.25, 16, 32)
const tailGeo = new THREE.ConeGeometry(.75, 2.75, 16, 3)
const hipGeo = new THREE.SphereGeometry(.1, 16, 32)
const upperLegGeo = new THREE.CylinderGeometry(.085, .1, 2, 32)
const kneeGeo = new THREE.SphereGeometry(.1, 16, 32)

const wingLength = .25, wingWidth = 1.2;
const shape = new THREE.Shape();
shape.moveTo(-wingLength / 2, -wingWidth / 2);
shape.lineTo(wingLength / 2, -wingWidth / 2);
shape.lineTo(wingLength / 2, wingWidth / 2);
shape.lineTo(-wingLength / 2, wingWidth / 2);
shape.lineTo(-wingLength / 2, -wingWidth / 2);

const extrudeSettings = {
    steps: 1,
    depth: 1.1,
    bevelEnabled: true,
    bevelThickness: 1,
    bevelSize: .3,
    bevelOffset: -.1,
    bevelSegments: 1
};

const wingGeo = new THREE.ExtrudeGeometry(shape, extrudeSettings)
const shoulderGeo = new THREE.SphereGeometry(.1, 16, 32)
const buttGeo = new THREE.SphereGeometry(.1, 16, 32)
const shinGeo = new THREE.CylinderGeometry(.075, .075, 2, 32)
const ankleGeo = new THREE.SphereGeometry(.1, 16, 32)
const toeGeo = new THREE.BoxGeometry(.075, .1, 1.25)
const featherGeo = new THREE.SphereGeometry(.2, 16, 32)

function bird() {

    this.headGroup = new THREE.Group()
    this.bodyGroup = new THREE.Group()
    this.legGroup = new THREE.Group()
    this.featherGroup = new THREE.Group()

    // make a head group
    this.head = new THREE.Mesh(
        headGeo, headMat
    )

    this.beak = new THREE.Mesh(
        beakGeo, beakMat
    )

    this.leftEye = new THREE.Mesh(
        eyeGeo, eyeMat
    )

    this.rightEye = new THREE.Mesh(
        eyeGeo, eyeMat
    )

    this.leftPupil = new THREE.Mesh(
        pupilGeo, pupilMat
    )

    this.rightPupil = new THREE.Mesh(
        pupilGeo, pupilMat
    )

    this.body = new THREE.Mesh(
        bodyGeo, bodyMat
    )

    this.neck = new THREE.Mesh(
        neckGeo, normalMat
    )

    this.leftWing = new THREE.Mesh(
        wingGeo, wingMat
    )

    this.rightWing = new THREE.Mesh(
        wingGeo, wingMat
    )

    this.leftShoulder = new THREE.Mesh(
        shoulderGeo, shoulderMat
    )

    this.rightShoulder = new THREE.Mesh(
        shoulderGeo, shoulderMat
    )

    this.butt = new THREE.Mesh(
        buttGeo, buttTailMat
    )

    this.tail = new THREE.Mesh(
        tailGeo, buttTailMat
    )

    // make leg group
    this.leftHip = new THREE.Mesh(
        hipGeo, legsMat
    )

    this.rightHip = new THREE.Mesh(
        hipGeo, legsMat
    )

    this.leftUpperLeg = new THREE.Mesh(
        upperLegGeo, legsMat
    )

    this.rightUpperLeg = new THREE.Mesh(
        upperLegGeo, legsMat
    )

    this.leftKnee = new THREE.Mesh(
        kneeGeo, legsMat
    )

    this.rightKnee = new THREE.Mesh(
        kneeGeo, legsMat
    )

    this.leftShin = new THREE.Mesh(
        shinGeo, legsMat
    )

    this.rightShin = new THREE.Mesh(
        shinGeo, legsMat
    )

    this.leftAnkle = new THREE.Mesh(
        ankleGeo, legsMat
    )

    this.rightAnkle = new THREE.Mesh(
        ankleGeo, legsMat
    )

    this.leftToe1 = new THREE.Mesh(
        toeGeo, legsMat
    )

    this.leftToe2 = this.leftToe1.clone()
    this.leftToe3 = this.leftToe1.clone()

    this.rightToe1 = new THREE.Mesh(
        toeGeo, legsMat
    )

    this.rightToe2 = this.rightToe1.clone()
    this.rightToe3 = this.rightToe1.clone()

    // make spotted feather group
    this.feather1 = new THREE.Mesh(
        featherGeo, featherMat
    )

    this.feather2 = this.feather1.clone()
    this.feather3 = this.feather1.clone()
    this.feather4 = this.feather1.clone()
    this.feather5 = this.feather1.clone()
    this.feather6 = this.feather1.clone()
    this.feather7 = this.feather1.clone()
    this.feather8 = this.feather1.clone()
    this.feather9 = this.feather1.clone()
    this.feather10 = this.feather1.clone()
    //
    this.headGroup.add(this.head)
    this.headGroup.add(this.beak)
    this.headGroup.add(this.leftEye)
    this.headGroup.add(this.rightEye)
    this.headGroup.add(this.leftPupil)
    this.headGroup.add(this.rightPupil)
    this.bodyGroup.add(this.leftShoulder)
    this.bodyGroup.add(this.rightShoulder)
    this.bodyGroup.add(this.neck)
    this.bodyGroup.add(this.body)
    this.bodyGroup.add(this.leftWing)
    this.bodyGroup.add(this.rightWing)
    this.bodyGroup.add(this.butt)
    this.bodyGroup.add(this.tail)
    this.legGroup.add(this.leftHip)
    this.legGroup.add(this.rightHip)
    this.legGroup.add(this.leftUpperLeg)
    this.legGroup.add(this.rightUpperLeg)
    this.legGroup.add(this.leftKnee)
    this.legGroup.add(this.rightKnee)
    this.legGroup.add(this.leftShin)
    this.legGroup.add(this.rightShin)
    this.legGroup.add(this.leftAnkle)
    this.legGroup.add(this.rightAnkle)
    this.legGroup.add(this.leftToe1)
    this.legGroup.add(this.leftToe2)
    this.legGroup.add(this.leftToe3)
    this.legGroup.add(this.rightToe1)
    this.legGroup.add(this.rightToe2)
    this.legGroup.add(this.rightToe3)
    this.featherGroup.add(this.feather1)
    this.featherGroup.add(this.feather2)
    this.featherGroup.add(this.feather3)
    this.featherGroup.add(this.feather4)
    this.featherGroup.add(this.feather5)
    this.featherGroup.add(this.feather6)
    this.featherGroup.add(this.feather7)
    this.featherGroup.add(this.feather8)
    this.featherGroup.add(this.feather9)
    this.featherGroup.add(this.feather10)

    // parent child relationships
    this.head.add(this.beak)
    this.head.add(this.leftEye)
    this.head.add(this.rightEye)
    this.leftEye.add(this.leftPupil)
    this.rightEye.add(this.rightPupil)
    this.neck.add(this.head)
    this.body.add(this.neck)
    this.body.add(this.leftShoulder)
    this.body.add(this.rightShoulder)
    this.leftShoulder.add(this.leftWing)
    this.rightShoulder.add(this.rightWing)
    this.body.add(this.butt)
    this.butt.add(this.tail)
    this.body.add(this.feather1)
    this.body.add(this.feather2)
    this.body.add(this.feather3)
    this.body.add(this.feather4)
    this.body.add(this.feather5)
    this.body.add(this.feather6)
    this.body.add(this.feather7)
    this.head.add(this.feather8)
    this.head.add(this.feather9)
    this.head.add(this.feather10)
    this.body.add(this.leftHip)
    this.body.add(this.rightHip)
    this.leftHip.add(this.leftUpperLeg)
    this.rightHip.add(this.rightUpperLeg)
    this.leftUpperLeg.add(this.leftKnee)
    this.rightUpperLeg.add(this.rightKnee)
    this.leftKnee.add(this.leftShin)
    this.rightKnee.add(this.rightShin)
    this.leftShin.add(this.leftAnkle)
    this.rightShin.add(this.rightAnkle)
    this.leftAnkle.add(this.leftToe1)
    this.leftAnkle.add(this.leftToe2)
    this.leftAnkle.add(this.leftToe3)
    this.rightAnkle.add(this.rightToe1)
    this.rightAnkle.add(this.rightToe2)
    this.rightAnkle.add(this.rightToe3)

    // position the head
    this.head.position.x = 0
    this.head.position.y = 0

    // position the eyes
    this.leftEye.applyMatrix4(new THREE.Matrix4().makeTranslation(.6, .2, .5));
    this.leftEye.rotation.y = THREE.Math.degToRad(30)
    this.rightEye.applyMatrix4(new THREE.Matrix4().makeTranslation(-.6, .2, .5));
    this.rightEye.rotation.y = THREE.Math.degToRad(-30)

    // position the pupils
    this.leftPupil.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, .1));
    this.rightPupil.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, .1));

    // position the beak 
    this.beak.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, 2.25));
    this.beak.rotation.x = THREE.Math.degToRad(90)

    // position the neck
    this.neck.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 2.6, 0));
    this.neck.rotation.x = THREE.Math.degToRad(-55)

    // position the body
    this.body.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, 0));
    this.body.rotation.x = THREE.Math.degToRad(60)

    // position the shoulders
    this.leftShoulder.applyMatrix4(new THREE.Matrix4().makeTranslation(1, .75, 0)); // .75, 0, 0 
    this.leftShoulder.rotation.x = THREE.Math.degToRad(-80)
    this.leftShoulder.rotation.y = THREE.Math.degToRad(-20) // 150

    this.rightShoulder.applyMatrix4(new THREE.Matrix4().makeTranslation(-1, .75, 0));
    this.rightShoulder.rotation.x = THREE.Math.degToRad(-80)
    this.rightShoulder.rotation.y = THREE.Math.degToRad(20) // 150

    // position the wings
    this.leftWing.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, -1.5)); // .75, 0 , 0
    this.rightWing.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, -1.5));

    // position the butt
    this.butt.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -.9, -.4)); //0, 1, .5
    this.butt.rotation.x = THREE.Math.degToRad(-140)

    // position the tail
    this.tail.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 2, 0));

    // position the hips
    this.leftHip.applyMatrix4(new THREE.Matrix4().makeTranslation(.5, 0, 1)); .5, -.5, 1
    this.leftHip.rotation.x = THREE.Math.degToRad(25) // -60
    this.rightHip.applyMatrix4(new THREE.Matrix4().makeTranslation(-.5, 0, 1));
    this.rightHip.rotation.x = THREE.Math.degToRad(-40)

    // position the upper legs
    this.leftUpperLeg.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0)); // 0, .75, 0
    this.rightUpperLeg.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0)); // 0, .75, 0

    // position the knees
    this.leftKnee.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));
    this.leftKnee.rotation.x = THREE.Math.degToRad(-60) // -60
    this.rightKnee.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));
    this.rightKnee.rotation.x = THREE.Math.degToRad(-60) // -60

    // position the shins
    this.leftShin.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));
    this.rightShin.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));

    // position the ankles
    this.leftAnkle.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));
    this.leftAnkle.rotation.x = THREE.Math.degToRad(40) // -60
    this.rightAnkle.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -1, 0));
    this.rightAnkle.rotation.x = THREE.Math.degToRad(40) // -60 40 

    // position the feet
    this.leftToe1.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, .6));
    this.leftToe2.applyMatrix4(new THREE.Matrix4().makeTranslation(-.2, 0, .6));
    this.leftToe2.rotation.y = THREE.Math.degToRad(-10)
    this.leftToe3.applyMatrix4(new THREE.Matrix4().makeTranslation(.2, 0, .6));
    this.leftToe3.rotation.y = THREE.Math.degToRad(15)

    this.rightToe1.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, .6));
    this.rightToe2.applyMatrix4(new THREE.Matrix4().makeTranslation(-.2, 0, .6));
    this.rightToe2.rotation.y = THREE.Math.degToRad(-15)
    this.rightToe3.applyMatrix4(new THREE.Matrix4().makeTranslation(.2, 0, .6));
    this.rightToe3.rotation.y = THREE.Math.degToRad(15)

    // position the feathers
    this.feather1.applyMatrix4(new THREE.Matrix4().makeTranslation(.05, 1.55, -.62));
    this.feather2.applyMatrix4(new THREE.Matrix4().makeTranslation(-.5, .75, -.6));
    this.feather3.applyMatrix4(new THREE.Matrix4().makeTranslation(-.25, .25, -.85));
    this.feather4.applyMatrix4(new THREE.Matrix4().makeTranslation(.4, .05, -.85));
    this.feather5.applyMatrix4(new THREE.Matrix4().makeTranslation(-.3, -.75, -1.07));
    this.feather6.applyMatrix4(new THREE.Matrix4().makeTranslation(.75, -.85, -.85));
    this.feather7.applyMatrix4(new THREE.Matrix4().makeTranslation(-1, -1.25, -.68));
    this.feather8.applyMatrix4(new THREE.Matrix4().makeTranslation(-.1, .8, -.15));
    this.feather9.applyMatrix4(new THREE.Matrix4().makeTranslation(.6, .2, -.5));
    this.feather10.applyMatrix4(new THREE.Matrix4().makeTranslation(-.78, -.2, .05));

        // turn on cast shadow
        this.head.castShadow = true; // true
        this.head.receiveShadow = false;
        this.beak.castShadow = true; // true
        this.beak.receiveShadow = false;
        this.leftEye.castShadow = false;
        this.leftEye.receiveShadow = false;
        this.leftEye.castShadow = false;
        this.rightEye.receiveShadow = false;
        this.beak.receiveShadow = false;
        this.body.castShadow = true; // true
        this.body.receiveShadow = false;
        this.leftWing.castShadow = true; // true
        this.leftWing.receiveShadow = false;
        this.rightWing.castShadow = true; // true
        this.rightWing.receiveShadow = false;
        this.tail.castShadow = true; // true
        this.tail.receiveShadow = false;
        this.leftUpperLeg.castShadow = true; // true
        this.leftUpperLeg.receiveShadow = false;
        this.rightUpperLeg.castShadow = true; // true
        this.rightUpperLeg.receiveShadow = false;
        this.leftShin.castShadow = true; // true
        this.leftShin.receiveShadow = false;
        this.rightShin.castShadow = true; // true
        this.rightShin.receiveShadow = false;
        this.leftToe1.castShadow = true; // true
        this.leftToe1.receiveShadow = false;
        this.leftToe2.castShadow = true; // true
        this.leftToe2.receiveShadow = false;
        this.leftToe3.castShadow = true; // true
        this.leftToe3.receiveShadow = false;
        this.rightToe1.castShadow = true; // true
        this.rightToe1.receiveShadow = false;
        this.rightToe2.castShadow = true; // true
        this.rightToe2.receiveShadow = false;
        this.rightToe3.castShadow = true; // true
        this.rightToe3.receiveShadow = false;

    //this.destination = new THREE.Vector3(-10, this.bodyGroup.position.y, -1);
    this.fw = 40; // size of flock circle originally 20 
    this.destination = new THREE.Vector3(flockDestination.x + Math.random() * this.fw - this.fw / 2, 0, flockDestination.z + Math.random() * this.fw - this.fw / 2);
    this.moveSpeed = .005;
    this.moveTopSpeed = .25; //. 1
    this.TopSpeedRun = .5// .5
    this.TopSpeedWalk = .25// 
    this.acceleration = .01;
    this.runCounter = Math.random() * 10;
    this.moving = true;
    this.realRot = 0;
    this.rotSpeed = .1;
    this.RotDir = true;
    this.seedGeometry = new THREE.SphereGeometry(.25, 24, 16); // .25, 24, 16
    this.seedMaterial = new THREE.MeshNormalMaterial(); //0xbcb1ae
    this.seedsphere = new THREE.Mesh(this.seedGeometry, this.seedMaterial);
    this.seedsphere.position.set(this.destination);

    // eating
    this.eatingPosition = 0; // 0 down 1 eating 2 up
    this.eatingTimer = 0;
    this.eatTimeLength = 1;

    // tail wag
    this.tailRot = 0;

    //START ROTATION
    this.bodyGroup.rotation.y = THREE.Math.degToRad(35); // test start rotation

    this.newDestination = function () {
        this.moving = true;
        this.destination = new THREE.Vector3(flockDestination.x + Math.random() * this.fw - this.fw / 2, 0, flockDestination.z + Math.random() * this.fw - this.fw / 2);
        this.RotDir = Math.random() < 0.5; // random true or false 

        let rotD = Math.atan2(this.destination.z - this.bodyGroup.position.z, this.destination.x - this.bodyGroup.position.x)
        let seedNudgeZ = Math.sin(rotD) * 2.3
        let seenNudgeX = Math.cos(rotD) * 2.3
        this.seedsphere.position.x = this.destination.x + seenNudgeX;
        this.seedsphere.position.y = groundPlane.position.y
        this.seedsphere.position.z = this.destination.z + seedNudgeZ;

        this.resetColor();
        // }
    }

    this.resetColor = function () {

        this.head.material = headMat;  // reset color 
        this.beak.material = beakMat;
        this.body.material = bodyMat;
        this.tail.material = buttTailMat;
        this.leftWing.material = wingMat;
        this.rightWing.material = wingMat;
        this.leftUpperLeg.material = legsMat;
        this.rightUpperLeg.material = legsMat;
        this.leftKnee.material = legsMat;
        this.rightKnee.material = legsMat;
        this.leftShin.material = legsMat;
        this.rightShin.material = legsMat;
        this.leftAnkle.material = legsMat;
        this.rightAnkle.material = legsMat;
        this.leftToe1.material = legsMat;
        this.leftToe2.material = legsMat;
        this.leftToe3.material = legsMat;
        this.rightToe1.material = legsMat;
        this.rightToe2.material = legsMat;
        this.rightToe3.material = legsMat;

    }

    this.moveAnimation = function () {

        if (changeLoc == true) {
            this.newDestination();
        }

        if (this.moving) {
            // unbob 
            this.eatingPosition == 0;
            this.body.rotation.x = 1.35 //1.04
            this.neck.rotation.x = -1.1 //1.04

            // get the angle between where you are and where you are going
            let rot = Math.atan2(this.destination.z - this.bodyGroup.position.z, this.destination.x - this.bodyGroup.position.x)

            if (runflag) {
                this.moveTopSpeed = this.TopSpeedRun;
            } else {
                this.moveTopSpeed = this.TopSpeedWalk;
            }

            if (this.moveSpeed < this.moveTopSpeed) {
                this.moveSpeed += this.acceleration;
            }
            if (this.moveSpeed > this.moveTopSpeed) {
                this.moveSpeed -= this.acceleration / 3;
            }


            //use the rotation and trig to rotate the bird as it walks
            if (Math.abs(rot - this.realRot) > .12) {
                if (this.RotDir) {
                    this.realRot += this.rotSpeed;
                }
                else {
                    this.realRot -= this.rotSpeed;
                }
                if (this.realRot > Math.PI) {
                    this.realRot = -Math.PI;
                }
                if (this.realRot < -Math.PI) {
                    this.realRot = Math.PI;
                }
            }

            //REMOVE!!!
            this.moveTopSpeed - .0001// take this line out 

            this.bodyGroup.position.z += Math.sin(this.realRot) * this.moveSpeed; // was this.rot
            this.bodyGroup.position.x += Math.cos(this.realRot) * this.moveSpeed;
            this.bodyGroup.rotation.y = -this.realRot + Math.PI / 2

            // get the distance to the destintation
            let loc = new THREE.Vector3(this.bodyGroup.position.x, 0, this.bodyGroup.position.z);
            let d = loc.distanceTo(this.destination);


            // animating the walk cycle 
            // runcounter is a number that goes up as you walk 

            this.runCounter += this.moveSpeed * .7; // make 4 higher to cycle faster 
            this.hipRot = Math.sin(this.runCounter) * 60; // 30 is the length of the step
            this.leftHip.rotation.x = THREE.Math.degToRad(-50) // -50 -60 is the original hip rotation
            this.rightHip.rotation.x = THREE.Math.degToRad(-50)//-50
            this.leftHip.rotation.x += THREE.Math.degToRad(this.hipRot) // adds the step to the starting position. 
            this.rightHip.rotation.x += THREE.Math.degToRad(-this.hipRot)

            this.kneeRot = Math.sin(this.runCounter) * 10; // 30 is the length of the step
            this.leftKnee.rotation.x = THREE.Math.degToRad(-20) //-40
            this.rightKnee.rotation.x = THREE.Math.degToRad(-20) //-40
            this.leftKnee.rotation.x += THREE.Math.degToRad(this.kneeRot)
            this.rightKnee.rotation.x += THREE.Math.degToRad(-this.kneeRot)

            this.ankleRot = Math.sin(this.runCounter) * 30; // 30 is the length of the step
            this.leftAnkle.rotation.x = THREE.Math.degToRad(60)//40 //1200
            this.rightAnkle.rotation.x = THREE.Math.degToRad(60) //40
            this.leftAnkle.rotation.x += THREE.Math.degToRad(-this.ankleRot)
            this.rightAnkle.rotation.x += THREE.Math.degToRad(this.ankleRot)

            if (d < 1) {

                this.moving = false;
                this.leftHip.rotation.x = THREE.Math.degToRad(-50)
                this.rightHip.rotation.x = THREE.Math.degToRad(-50)
                this.leftAnkle.rotation.x = THREE.Math.degToRad(0)//40 //1200
                this.rightAnkle.rotation.x = THREE.Math.degToRad(0) //40
                this.eatingPosition = 0;
            }

        }

        let bobSpeed = .1;

        if (this.moving == false && this.eatingPosition == 0) {
            this.neck.rotation.x = -.75

            if (this.body.rotation.x < 2.1) { //2.1
                this.body.rotation.x += bobSpeed
                this.leftHip.rotation.x -= bobSpeed
                this.rightHip.rotation.x -= bobSpeed
            } else {
                if (!isAnyoneEating) {
                    isAnyoneEating = true
                }
                this.eatingPosition = 1;
                this.eatingTimer = elapsedTime;
                this.eatTimeLength = Math.random() + .5
                this.seedsphere.position.y = groundPlane.position.y - 10

                //SYNTH
                const now = Tone.now()
                let x = Math.floor(Math.random() * toneArray.length)
                synth.triggerAttackRelease(toneArray[x], "8n");

                // bird colors
                for (let i = 0; i < birds.length; i++) {
                    var c1 = headColorArray[Math.floor(Math.random() * headColorArray.length)];
                    var c2 = bodyColorArray[Math.floor(Math.random() * bodyColorArray.length)];
                    var c3 = wingColorArray[Math.floor(Math.random() * wingColorArray.length)];
                    var c4 = legColorArray[Math.floor(Math.random() * legColorArray.length)];
                    birds[i].head.material = new THREE.MeshLambertMaterial({ color: c2 });
                    birds[i].body.material = new THREE.MeshLambertMaterial({ color: c2 });
                    birds[i].tail.material = new THREE.MeshLambertMaterial({ color: c2 });
                    birds[i].leftWing.material = new THREE.MeshLambertMaterial({ color: c3 });
                    birds[i].rightWing.material = new THREE.MeshLambertMaterial({ color: c3 });
                    birds[i].leftUpperLeg.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightUpperLeg.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftKnee.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightKnee.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftShin.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightShin.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftAnkle.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightAnkle.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftToe1.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftToe2.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].leftToe3.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightToe1.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightToe2.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].rightToe3.material = new THREE.MeshLambertMaterial({ color: c4 });
                    birds[i].beak.material = new THREE.MeshLambertMaterial({ color: c4 });


                }
            }
        }

        if (this.moving == false && this.eatingPosition == 1) {

            this.butt.rotation.x = -2.5 //2.443
            this.tailRot += .2; // speed of wag
            this.tailRotSin = Math.sin(this.tailRot) * 5; // 2 range of wag
            this.butt.rotation.x += THREE.Math.degToRad(this.tailRotSin)

            if (elapsedTime - this.eatingTimer > this.eatTimeLength) {  // this.eatTimeLength 

                // bird colors
                this.resetColor();
                this.eatingPosition = 2;
            }
        }

        if (this.moving == false && this.eatingPosition == 2) {
            this.butt.rotation.x = -2.443 //2.443
            if (this.body.rotation.x > 1.04) {
                this.body.rotation.x -= bobSpeed
                this.leftHip.rotation.x += bobSpeed
                this.rightHip.rotation.x += bobSpeed
            } else {
                this.moving = true;
                this.eatingPosition == 0;
                this.newDestination();
                //console.log("zerO")
            }
        }


    }
}

const gpGeometry = new THREE.PlaneBufferGeometry(1000, 1000, 4, 4);
const gpMaterial = new THREE.LineBasicMaterial({ color: "rgb(150, 80, 20)" });


let birds = [];
for (let i = 0; i < 7; i++) { // number of birds originally 8
    birds[i] = new bird();
    //scene.add(birds[i].group);
    scene.add(birds[i].headGroup)
    scene.add(birds[i].bodyGroup)
    scene.add(birds[i].legGroup)
    scene.add(birds[i].featherGroup)//
    scene.add(birds[i].seedsphere);
}

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// Event listener
window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // TEST - experimenting with autoClear here and at end of code
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.autoClear = false;

    // TEST - Moved to renderer section
    // Turn on shadows in the renderer
    // renderer.shadowMap.enabled = true;
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
})

// CAMERA
const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height) //
camera.position.set(-25, 40, 55);
camera.lookAt(new THREE.Vector3(0, -10, 10))

let camNum = 0;
let cameraTimer = 0;
let cameraInterval = 30; // seconds until it changes

let startGoing = false
document.addEventListener('click', changeCamera, false);
function changeCamera() {

    if (startGoing == false) {
        startGoing = true;
        speakPoem(true)
        tick();
        let element = document.getElementById('overlay'); // test 
        element.parentNode.removeChild(element);

    } else {

        camNum++;
        if (camNum > 2) {
            camNum = 0;
        }
        console.log(camNum)

        if (camNum == 0) {
            camera.position.set(-25, 40, 55); //   -25, 40, 55
            camera.lookAt(new THREE.Vector3(0, -10, 10))

        }
        if (camNum == 1) {
            camera.position.set(0, 20, 22);
            camera.lookAt(new THREE.Vector3(0, -10, -10)) // 0, -10, -5
        }

    }
    Tone.start()
}

// HERE indicates I turned the voice off
let voices = [];
function speakPoem(_firstTime) {
    utter = new SpeechSynthesisUtterance();
    if(_firstTime){
        utter.text = " "; 
        // voices = speechSynthesis.getVoices();
        // utter.voice = voices[0];
        console.log(voices);
    }else{
        utter.text = messageArray[messageNum];
    }
    utter.lang = 'en-US';
    utter.rate = .6; // 1.2
    utter.volume = .2;
    // utter.voice = voices[48];
    utter.pitch = 1.5;
    // HERE speechSynthesis.speak(utter);
}

scene.add(camera)

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: false,
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// TEST
// Turn on shadows in the renderer
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

//WAVE
function wave(_ln, _wh, _greenVal, _z0ffset, _dir, _waveC) {
    this.waveSize = 150;  // 150 total x width of wave 
    this.stP = new Vector2(this.waveSize, 0);  // top right location of wave
    this.waveX = 5;  // length of the wave crest 
    this.waveY = 20;  // 16 width between waves 
    let w = 3;
    this.curve = new THREE.SplineCurve([
        new THREE.Vector2(this.stP.x - this.waveSize, this.stP.y),// bottom left 
        new THREE.Vector2(this.stP.x + this.waveX + Math.random() * w, this.stP.y),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 2),// trough 1 
        new THREE.Vector2(this.stP.x + this.waveX + Math.random() * w, this.stP.y + this.waveY * 3),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 4),// trough 2 
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 5),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 6),// trough 3 
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 7),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 8),// trough 4 
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 9),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 10),// trough 5 
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 11),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 12),// trough 6
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 13),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 14),// trough 7
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 15),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 16),// trough 7
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 17),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 18),// trough 7
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 19),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 20),// trough 7
        new THREE.Vector2(this.stP.x + this.waveX, this.stP.y + this.waveY * 21),// crest
        new THREE.Vector2(this.stP.x, this.stP.y + this.waveY * 22),// trough 7

        new THREE.Vector2(this.stP.x - this.waveSize, this.stP.y + this.waveY * 22), //top left
    ]);

    this.points = this.curve.getPoints(260);
    this.points.autoClose = true;
    this.waveShape = new THREE.Shape(this.points);
    this.extrudeSettings = { depth: 1, bevelEnabled: true, bevelSegments: 2, steps: 2, bevelSize: 4, bevelThickness: 1 };
    this.geometry = new THREE.ExtrudeGeometry(this.waveShape, this.extrudeSettings);
    this.material = new THREE.MeshLambertMaterial({ color: _waveC, transparent: true, opacity: 0.5 })
    // this.material = new THREE.MeshNormalMaterial({ color: _waveC, transparent: true, opacity: 0.95 })
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.rotateX(THREE.Math.degToRad(-90))
    let r = Math.random() * 6 - 3
    this.mesh.rotation.z = THREE.Math.degToRad(r)

    this.mesh.position.y = -5;
    this.mesh.position.x = -250;
    this.mesh.position.z = 60 + _z0ffset;
    this.startVector = new Vector3(this.mesh.position.x - 50, this.mesh.position.y, this.mesh.position.z)
    this.endVector = new Vector3(this.mesh.position.x + 100, this.mesh.position.y, this.mesh.position.z)

    this.lerpPos = _ln; // _ln so waves are opposite
    this.lerpSpeed = .002;
    this.lerpSpeedIn = .01;
    this.lerpSpeedOut = .003; // .003 is nice 
    this.lerpDir = _dir;
    this.yNudge = 0;
    this.lastSinlerp = 0;
    this.kill = false;

    this.play = function () {

        this.lerpPos += this.lerpSpeed;
        this.sinlerp = (Math.sin(this.lerpPos) + 1) / 2;
        this.mesh.position.lerpVectors(this.startVector, this.endVector, this.sinlerp)
        if (this.lastSinlerp < this.sinlerp) {
            if (this.lerpDir == false) { // bottom of wave
                this.startVector.y = -5;
                r = Math.random() * 8 - 4
                this.mesh.rotation.z = THREE.Math.degToRad(r)
            }
            this.lerpDir = true;
            this.lerpSpeed = this.lerpSpeedIn;
        } else {
            if (this.lerpDir == true) { // when the wave crests 
                this.startVector.y = -6;
            }
            this.lerpDir = false;
            this.lerpSpeed = this.lerpSpeedOut
        }
        this.lastSinlerp = this.sinlerp
    }
}

let waveC = new THREE.Color("rgb(0, 40, 255)"); //0028FF
let waveObj = new wave(.01, .1, 0, 0, true, waveC)
waveC = new THREE.Color("rgb(0, 80, 255)");
let waveObj2 = new wave(Math.PI, 0, 100, 5, true, waveC)


scene.add(waveObj.mesh);
scene.add(waveObj2.mesh);

let timer = clock.getElapsedTime(); // like millis() 
let duration = 3
let possible = "A BCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()?><.,:;";
let textTimer = 0;
let textInterval = 15; // measured in cycles not seconds so we don't have to mess with getting millis() for now 
let message;

const tick = () => {

    elapsedTime = clock.getElapsedTime()
    waveObj.play();
    waveObj2.play();


    if (camNum == 2) {

        let nc = waveObj.mesh.position;

        camera.position.set(nc.x + 150, 8, -5);
        camera.lookAt(new THREE.Vector3(50, 5, -5)) // 50, 5, -5
    }


    // mix up text 
    if (!isAnyoneEating) {
        message = messageArray[messageNum];
    }

    if (isAnyoneEating) {
        textTimer++;

        if (textTimer >= textInterval) {
            // console.log(textTimer)
            let newLetter = possible.charAt(Math.floor(Math.random() * possible.length));

            // let message = messageArray[messageNum];
            let index = Math.floor(Math.random() * message.length)
            message = message.substring(0, index) + newLetter + message.substring(index + 1);
            //document.getElementById("overlay").innerHTML = message //HERE
            textTimer = 0;
        }
    }

    if (elapsedTime - cameraTimer > cameraInterval) {
        cameraTimer = elapsedTime;
        changeCamera();
    }
    let waveX = waveObj.mesh.position.x + waveObj.waveSize + waveObj.waveX;
    let waveX2 = waveObj2.mesh.position.x + waveObj2.waveSize + waveObj2.waveX;

    if (clock.getElapsedTime() - timer > duration) { // timer for moving the destination, could be more random
        changeLoc = true;
        duration = 5; // was 3 , 6
        isAnyoneEating = false;

        timer = clock.getElapsedTime();
        let cWaveX;
        if (waveX > waveX2) {
            cWaveX = waveX
        }
        else {
            cWaveX = waveX2
        }
        if (waveObj.lerpDir == false && waveObj2.lerpDir == false) {
            flockDestination = new THREE.Vector3(Math.random() * 3 + cWaveX, 0, Math.random() * 40 - 20);
        } else {
            flockDestination = new THREE.Vector3(Math.random() * 20 + cWaveX, 0, Math.random() * 40 - 20);
        }

        // change message 
        messageNum++
        if (messageNum >= messageArray.length) {
            messageNum = 1;
        }

        //document.getElementById("overlay").innerHTML = messageArray[messageNum];
        speakPoem(false);

    }

    let btw = false; // btw (birds touching water)
    for (let i = 0; i < birds.length; i++) {
        if (birds[i].bodyGroup.position.x - waveX < 1 && waveObj.lerpDir == true) { // if the wave touches any bird AND the wave is coming in 
            btw = true;
        }
        if (birds[i].bodyGroup.position.x - waveX2 < 1 && waveObj2.lerpDir == true) {
            btw = true;
        }
    }
    if (btw && runflag == false) {
        duration = 5;  // 4
        changeLoc = true;
        flockDestination = new THREE.Vector3(40, 0, Math.random() * 40 - 20);
        timer = clock.getElapsedTime();
        runflag = true;
    }
    for (let i = 0; i < birds.length; i++) {
        birds[i].moveAnimation();
    }
    if (!btw) {
        runflag = false;
    }

    renderer.clear();
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
    changeLoc = false;

}

renderer.render(scene, camera)
